/* eslint-disable import/default */
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import App from './App'
import './index.css'

Sentry.init({
    dsn: import.meta.env.SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
        'localhost',
        `${import.meta.env.VITE_API_BASE_URL}${
            import.meta.env.VITE_API_VERSION
        }`,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: 'Oqoodi@2.0.50',
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <App />
)
